var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:(_vm.intraction)},[(_vm.PlayerApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.PlayerApi}}):_vm._e(),(_vm.EventApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.EventApi}}):_vm._e(),(_vm.InventoryApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.InventoryApi}}):_vm._e(),(_vm.QuestApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.QuestApi}}):_vm._e(),(_vm.FinisherApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.FinisherApi}}):_vm._e(),(_vm.error)?_c('ErrorDialog',{attrs:{"error":_vm.error,"message":_vm.errorMessage},on:{"exitError":_vm.extiError}}):_vm._e(),_c('RecalculateConfirmationDialog',{attrs:{"confirm":_vm.confirm,"type":"event_math"},on:{"exitConfirm":_vm.exitConfirm}}),(_vm.tutorialDialog && _vm.event.translatableTutorialVideoUrl)?_c('TutorialDialog',{attrs:{"callbackClose":() => {
        _vm.tutorialDialog = false;
        _vm.$router.go();
      },"showDialog":true,"link":JSON.parse(_vm.event.translatableTutorialVideoUrl)[_vm.$_getLocale()]}}):_vm._e(),(_vm.quests.length > 0)?_c('div',[_c('div',{staticClass:"text-h5 abc-title",attrs:{"id":"QUEST"}},[_vm._v(" "+_vm._s(_vm.$t("string.quest"))+" ")]),_c('v-carousel',{staticClass:"pb-8",attrs:{"show-arrows":false,"height":"auto","hide-delimiter-background":"","draggable":"","value":_vm.currentQuest}},_vm._l((_vm.quests),function(item){return _c('v-carousel-item',{key:item.key,attrs:{"value":item.key}},[_c('v-card',{staticClass:"my-2 list-card-z",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-no-wrap align-center"},[_vm._l((item.Reward),function(rwd,j){return _c('div',{key:j + 'qi',staticClass:"text-center mx-4 mt-2"},[(item.isEvent)?_c('div',[_c('v-img',{attrs:{"contain":"","max-height":"60","max-width":"60","src":rwd.img}})],1):_c('div',[_c('v-img',{attrs:{"contain":"","max-height":"50","max-width":"50","src":rwd.Item.images.icon.url}}),_c('div',{staticClass:"me-1"},[_vm._v("+"+_vm._s(rwd.quantity))])],1)])}),_c('div',{staticClass:"pa-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"d-flex flex-no-wrap align-center"},[_c('div',{staticClass:"pe-3",staticStyle:{"width":"100%"}},[_c('v-progress-linear',{attrs:{"value":item.progress,"color":"green","height":"20","rounded":""}},[_vm._v(" "+_vm._s(item.numerator)+" / "+_vm._s(item.denominator))])],1),(item.PlayerQuest.timestampClaimed)?_c('v-icon',{attrs:{"large":"","color":"green"}},[_vm._v(" mdi-check-circle ")]):(
                    (item.isEvent &&
                      item.progress == 100 &&
                      item.isCompleted) ||
                    (!item.isEvent && item.progress == 100)
                  )?_c('v-btn',{staticClass:"text-h6 constant-tilt-shake",attrs:{"color":"yellow","loading":_vm.QuestApi.isLoading && _vm.selectedItem == item},on:{"click":function($event){item.isEvent ? _vm.gift() : _vm.claimQuest(item)}}},[_vm._v(" "+_vm._s(_vm.$t("action.claim"))+" ")]):_c('v-btn',{attrs:{"color":"grey","dark":"","depressed":""}},[_c('v-icon',[_vm._v(" mdi-lock ")])],1)],1)])],2)])],1)}),1)],1):_vm._e(),(_vm.quests.length > 0)?_c('v-divider',{staticClass:"my-3"}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between py-2 align-center"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"position":"relative"}},[_c('v-btn',{attrs:{"disabled":!_vm.isOngoing,"id":"StartTutorial","small":"","color":"yellow"},on:{"click":_vm.clickTutorial}},[_vm._v(" "+_vm._s(_vm.$t("string.tutorial"))+" "),(_vm.isGameTutCompleted && _vm.isOngoing)?_c('v-img',{staticClass:"ms-2",attrs:{"src":require('@/assets/explore/menu/tick.png'),"width":"20"}}):_vm._e(),(!_vm.isOngoing)?_c('v-icon',[_vm._v(" mdi-lock ")]):_vm._e()],1)],1),_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$t("string.totalScore"))+" : "+_vm._s(_vm.totalScore)+" ")]),_c('div',{staticClass:"d-flex align-center ticket-card"},[_c('v-img',{attrs:{"contain":"","width":"30","src":require('@/assets/explore/menu/ticket.png')}}),(_vm._event_data.Save)?_c('div',{staticClass:"text-h5 ps-2 game-title"},[_vm._v(" "+_vm._s(JSON.parse(_vm._event_data.Save.data).token)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"pa-1 d-flex flex-wrap justify-space-between",staticStyle:{"position":"relative"}},_vm._l((_vm.levels),function(level,i){return _c('div',{key:i,staticClass:"level-cart",attrs:{"id":i == 0 ? 'StartLevel' : ''},on:{"click":() => {
          _vm.$intro().exit();
          if (_vm.isOngoing) {
            _vm.$router.push({
              name: 'PageEventLevelDetail',
              params: { id: level.Level.id },
            });
          }
        }}},[_c('div',{staticClass:"level-img"},[_c('div',{staticClass:"container-1x1"},[_c('div',{staticClass:"aspect-ratio-item"},[(_vm.bossList[i])?_c('v-img',{class:_vm.bossList[i].inventory > 0 ? '' : 'no-boss',attrs:{"src":_vm.bossList[i].images.icon.url,"width":"150"}}):_vm._e()],1)])]),_c('div',{staticClass:"d-flex justify-center text-h5 mt-2",staticStyle:{"background-color":"#cbb18f"}},[_vm._v(" "+_vm._s(level.EventRecord ? level.EventRecord.topScore : "000")+" ")]),_c('div',{staticClass:"d-flex justify-space-around my-2"},[_c('v-icon',{attrs:{"color":level.EventRecord && level.EventRecord.topStar > 0
              ? 'yellow darken-2'
              : 'gray'}},[_vm._v(" mdi-star")]),_c('v-icon',{attrs:{"color":level.EventRecord && level.EventRecord.topStar > 1
              ? 'yellow darken-2'
              : 'gray'}},[_vm._v(" mdi-star")]),_c('v-icon',{attrs:{"color":level.EventRecord && level.EventRecord.topStar > 2
              ? 'yellow darken-2'
              : 'gray'}},[_vm._v(" mdi-star")])],1),_c('div',{staticClass:"level-go"},[_c('v-btn',{staticClass:"text-h6",attrs:{"disabled":!_vm.isOngoing,"color":"yellow","width":"100%"},on:{"click":() => {
              _vm.$intro().exit();
              _vm.$router.push({
                name: 'PageEventLevelDetail',
                params: { id: level.Level.id },
              });
            }}},[(_vm.isOngoing)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("action.go"))+" ")]):_c('v-icon',[_vm._v(" mdi-lock ")])],1)],1)])}),0),_c('div',{staticClass:"text-center py-2"},[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("string.recalculate"))+" ")]),_c('div',{staticClass:"py-2"},[_c('v-btn',{attrs:{"small":"","dark":"","color":"orange"},on:{"click":_vm.openConfirm}},[_vm._v(" "+_vm._s(_vm.$t("action.recalculate"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-reload ")])],1)],1)]),_c('v-dialog',{attrs:{"width":"460","content-class":"mail-box","overlay-opacity":"0.8","persistent":""},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}},[(_vm.mailOpen)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"mt-3 mail-text text-h4"},[_vm._v(_vm._s(_vm.$t("string.you_got")))]),_c('div',{staticClass:"mail-text text-h3 d-flex align-center"},[_c('v-img',{attrs:{"contain":"","width":"80","src":require('@/assets/explore/menu/ticket.png')}}),_vm._v(" X 20 ")],1),_c('v-img',{staticClass:"mx-auto mail-img",attrs:{"src":require('@/assets/explore/mail_open.png'),"width":"70%","contain":""}}),_c('v-btn',{staticClass:"text-h5 mb-3",attrs:{"height":"60","width":"200","color":"yellow"},on:{"click":() => {
            _vm.startTut();
            _vm.mail = false;
          }}},[_vm._v(" OK ")])],1):_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"mt-3 mail-text text-h3"},[_vm._v("Tata!")]),_c('div',{staticClass:"mail-text text-h4"},[_vm._v(_vm._s(_vm.$t("string.you_have_mail")))]),_c('v-img',{staticClass:"mx-auto mail-img",attrs:{"src":require('@/assets/explore/mail_close.png'),"width":"70%","contain":""}}),_c('v-btn',{staticClass:"text-h5 mb-3",attrs:{"height":"60","width":"200","color":"yellow"},on:{"click":() => {
            _vm.mailOpen = true;
          }}},[_vm._v(" "+_vm._s(_vm.$t("string.open"))+" ")])],1)]),(_vm.tutorialChat && _vm.chatStart)?_c('AbstractChat',{attrs:{"X1":"Hamochie","chats":_vm.tutorialChat,"callback":_vm.callback}}):_vm._e(),(_vm.rwdDialog)?_c('RewardDialog',{attrs:{"callbackClose":() => (_vm.rwdDialog = false),"showDialog":true,"rewards":_vm.rewards}}):_vm._e(),_c('SkipTut')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }